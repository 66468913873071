<template>
    <el-dialog title="确认周报" :visible.sync="dialogVisible" :close-on-click-modal="false" width="700px">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="100px" class="border-form">
            <el-row class="set_border">
                <el-col :span="10" class="col-set_border">
                    <el-form-item label="确认人" prop="refirmMan" class="item">
                        <el-input v-model="dataForm.refirmMan" placeholder="请填写" style="width: 130px;"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10" class="col-set_border">
                    <el-form-item label="确认时间" prop="refirmTime" class="item">
                        <el-date-picker v-model="dataForm.refirmTime" value-format="yyyy-MM-dd" placeholder="请选择"
                            style="width: 140px;" clearable>
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="2" style="margin-left: 30px;margin-top: 5px;">
                    <el-button v-preventReClick class="btn" type="primary" size="small"
                        @click="dataFormSubmit">确定</el-button>
                </el-col>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js"
export default {
    name: "weekly-refirm",
    data() {
        return {
            dialogVisible: true,
            form: {},
            action: "",
            dataForm: {
                recNo: '',
                refirmMan: '',
                refirmTime: '',
            },
            dataRule: {
                refirmMan: [
                    { required: true, message: "请填写确认人！ ", trigger: "blur" }
                ],
                refirmTime: [
                    { required: true, message: "请填写确认时间！ ", trigger: "blur" }
                ],
            }
        };
    },
    components: {

    },
    activated() {

    },
    methods: {
        getCurrentTime() {
            //获取当前时间并打印
            var _this = this;
            let yy = new Date().getFullYear();
            let mm = new Date().getMonth() + 1;
            let dd = new Date().getDate();
            let hh = new Date().getHours();
            let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
            let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
            return yy + '-' + mm + '-' + dd;
        },
        init(id, refirmMan, refirmTime) {
            this.dataForm = {
                recNo: id,
                refirmMan: refirmMan,
                refirmTime: refirmTime,
            }
            this.$nextTick(() => {
                this.dialogVisible = true;
            })
            this.$refs['dataForm'].resetFields();

        },
        dataFormSubmit() {
            this.$refs["dataForm"].validate(valid => {
                if (valid) {
                    let _url = "/business/projectweeklyreportinfo/projectWeeklyRefirm";
                    this.$http({
                        url: this.$store.state.httpUrl + _url,
                        method: "post",
                        data: this.dataForm
                    }).then(({ data }) => {
                        if (data && data.resultCode === 200) {
                            this.$message({
                                message: "操作成功",
                                type: "success",
                                duration: 1500,
                                onClose: () => {
                                    this.dialogVisible = false;
                                    this.$emit("refreshDataList");
                                }
                            });
                        }
                    });
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
    width: 1030px !important;
}

.addImgBox {
    width: 136px;
    height: 136px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    position: relative;
}

.el-divider {
    margin: 60px 0;
    background: 0 0;
    border-top: 1px solid #E6EBF5;
}

.tmp {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 248px;
    display: inline-block;
    color: #00428e;
    margin-top: 13px;
}

.item::v-deep .el-form-item__label {
    color: #00428E !important;
    margin-right: 10px !important;
    font-weight: bold !important;
    background-color: #E1F1FF;
    white-space: nowrap !important;
    border-right: solid #dadada 1px;
}

::v-deep .el-form-item {
    align-items: stretch !important;
    margin-bottom: 0px !important;
}

.border-form .el-row.set_border .col-set_border {
    border: solid #dadada 1px;
}
</style>
